import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import Link from './Link';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


const styles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

function ScrollTop(props) {
    const { window } = props;
    const classes = styles();

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 80,
    });

    return (
        <Link to="#app">
            <Zoom in={trigger}>
                <Fab color="secondary" size="large" aria-label="scroll back to top" className={classes.root}>
                    <KeyboardArrowUpIcon fontSize="large"/>
                </Fab>
            </Zoom>
        </Link >
    );
}

export default ScrollTop;