import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import city from '../../static/images/city.jpg';
import Project from './Project';

const styles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4, 0),
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
}));

function Projects() {
    const classes = styles();

    return (
        <div id="projects" className={classes.root}>
            <Project image={city} to="bloonscape" title="BloonScape"/>
        </div>
    );
}

export default Projects;