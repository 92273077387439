import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { blue } from '@material-ui/core/colors';
import Link from './Link';

const styles = makeStyles((theme) => ({
    container: {
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(2)}px)`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
    logo: {
        textAlign: 'center',
    },
    outlinedFab: {
        marginTop: theme.spacing(3),
        backgroundColor: 'transparent',
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        borderStyle: 'solid',
        borderWidth: 2,
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            color: blue[400],
            borderColor: blue[400],
        },
    },
}));

function Introduction() {
    const classes = styles();

    return (
        <div id="introduction" className={classes.container}>
            <div className={classes.logo}>
                <Typography variant="h1" color="inherit">
                    CookieDoh Studios
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                    Based in Philadelphia, PA
                </Typography>
            </div>
            <Link to="#projects" className={classes.root}>
                <Fab size="medium" aria-label="scroll to projects" className={classes.outlinedFab}>
                    <KeyboardArrowDownIcon />
                </Fab>
            </Link>
        </div>
    );
}

export default Introduction;