import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Link from '../Widgets/Link';
import Icon from '../../static/images/games/bloonscape/icon.png';
import Image1 from '../../static/images/games/bloonscape/demo/1.jpg';
import Image2 from '../../static/images/games/bloonscape/demo/2.jpg';
import Image3 from '../../static/images/games/bloonscape/demo/3.jpg';
import GoogleStore from '../../static/images/games/bloonscape/buttons/GoogleBadge.png';
import AppleStore from '../../static/images/games/bloonscape/buttons/AppStore.svg';
import { Carousel } from 'react-responsive-carousel';

const styles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4, 0),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(4),
    },
    body: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(4),
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: theme.spacing(4),
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    appIcon: {
        height: 200,
        width: 200,
        objectFit: 'cover',
        objectPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: 25,
    },
    play: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    playButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: theme.spacing(4),
    },
    carouselImage: {
        borderRadius: 25,
    },
    preview: {
        marginBottom: theme.spacing(4),
    },
}));

function Darryl() {
    const classes = styles();

    const getConfigurableProps = () => ({
        showArrows: true,
        showStatus: false,
        showIndicators: true,
        infiniteLoop: false,
        showThumbs: false,
        useKeyboardArrows: false,
        autoPlay: false,
        swipeable: true,
        dynamicHeight: true,
        emulateTouch: false,
        autoFocus: false,
        transitionTime: 500,
    });

    useEffect(() => {
        document.title = "BloonScape";
    });

    return (
        <div className={classes.root}>
            <div className={classes.titleContainer}>
                <img className={classes.appIcon} src={Icon} alt="BloonScape" />
                <div>
                    <Typography variant="h1" className={classes.title}>BloonScape</Typography>
                    <Typography variant="body1" style={{ textAlign: 'center' }}>Release Date: 2/22/2022</Typography>
                </div>
            </div>
            <div className={classes.body}>
                <div className={classes.preview}>
                    <Carousel {...getConfigurableProps()}>
                        <div>
                            <img src={Image1} className={classes.carouselImage}/>
                        </div>
                        <div>
                            <img src={Image2} className={classes.carouselImage}/>
                        </div>
                        <div>
                            <img src={Image3} className={classes.carouselImage}/>
                        </div>
                    </Carousel>
                </div>
                <div className={classes.play}>
                    <Typography variant="h3" style={{ textAlign: 'center' }}>Out Now:</Typography>
                    <div className={classes.playButtons}>
                        <Link to="https://play.google.com/store/apps/details?id=com.CookieDohStudios.DarrylFalls&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank">
                            <img src={GoogleStore} alt="Get it on Google Play" style={{ height: '110px' }} />
                        </Link>
                        <Link to="https://apps.apple.com/us/app/bloonscape/id1596340548" target="_blank">
                            <img src={AppleStore} alt="Get it on Google Play" style = {{ height: '110px', padding: '17px' }} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Darryl;