import React, { useState, useEffect } from 'react';

import Router from './Components/Pages/Router.js';
import { BrowserRouter } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

import Navbar from './Components/Widgets/Navbar';
import Footer from './Components/Widgets/Footer';
import ScrollTop from './Components/Widgets/ScrollTop';
import Divider from '@material-ui/core/Divider';

const bgPhotos = [
  require('./static/images/bg/philly01.jpg'),
  require('./static/images/bg/philly02.jpg'),
  require('./static/images/bg/philly03.jpg'),
];

function randomPhoto() {
  return bgPhotos[Math.floor(Math.random() * bgPhotos.length)];
}

const styles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  background: {
    position: 'fixed',
    left: '0',
    right: '0',
    zIndex: '-1',
    display: 'block',
    width: '100%',
    height: '100%',
    transform: 'scale(1.1)',
    filter: 'blur(5px)',
    backgroundImage: 'url(' + randomPhoto() + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    background: 'fixed',
  },
  container: {
    width: '100vw',
    paddingTop: theme.spacing(2),
    backgroundColor: 'rgba(230,230,230,0.7)',
    backdropFilter: 'blur(25px)',
    ["@media (min-width:768px)"]: { width: 700 },
    ["@media (min-width:992px)"]: { width: 800 },
    ["@media (min-width:1300px)"]: { width: 1000 },
    ["@media (min-width:1700px)"]: { width: 1300 },
    ["@media (min-width:2200px)"]: { width: 1700 },
    ["@media (min-width:2800px)"]: { width: 2200 },
  },
  body: {
    padding: theme.spacing(0, 3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(3)}px)`,
  },
}));

function App() {
  const classes = styles();
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    updateScreenSize();
    window.addEventListener('resize', updateScreenSize);
  });

  const updateScreenSize = () => {
    const smallQuery = window.matchMedia('(max-width: 767px)');
    setMobile(smallQuery.matches);
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <BrowserRouter>
        <div className={classes.background}></div>
        <div className={classes.page} id="app">
          <div className={classes.container}>
            <Navbar mobile={mobile} />
            <div className={classes.body}>
              <Router />
              <Divider variant="middle" />
              <Footer />
            </div>
          </div>
        </div>
        <ScrollTop />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
