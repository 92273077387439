import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AmpStories, AccountCircle, ContactMail, Policy } from '@material-ui/icons';
import Link from './Link';
import icon from '../../static/images/logo/icon.svg';

const styles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    appBar: {
        background: /* theme.palette.grey[900] */ 'transparent',
        elevation: 0,
        boxShadow: 'none',
    },
    sideBar: {
        padding: theme.spacing(4, 0),
        margin: theme.spacing(0, 2),
    },
    toolbar: {
        justifyContent: 'space-between',
    },
    toolbarMobile: {
        justifyContent: 'center',
    },
    navLink: {
        padding: theme.spacing(1, 2),
        margin: theme.spacing(0, 0, 0, 2),
    },
    logoContainer: {
        width: 32,
    },
    logo: {
        maxWidth: 32,
    }
}));

function Navbar({ mobile }) {
    const [open, setOpen] = useState(false);

    const toggle = (event) => {
        setOpen(!open);
    };

    const classes = styles();

    return (
        <div className={classes.root} id="navbar">
            <AppBar className={classes.appBar} position="static">
                <Toolbar className={mobile ? classes.toolbarMobile : classes.toolbar}>
                    {mobile &&
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggle}>
                            <MenuIcon />
                        </IconButton>
                    }

                    <Link to={"/"} className={classes.logoContainer}>
                        <img alt="CookieDoh Studios" src={icon} className={classes.logo}/>
                    </Link>

                    {!mobile &&
                        <div>
                            <Link to="/#projects"><Button color="inherit" className={classes.navLink}>Projects</Button></Link>
                            <Link to="/#about"><Button color="inherit" className={classes.navLink}>About</Button></Link>
                            <Link to="/#support"><Button color="inherit" className={classes.navLink}>Support</Button></Link>
                            <Link to="/privacy"><Button color="inherit" className={classes.navLink}>Privacy Policy</Button></Link>
                        </div>
                    }
                </Toolbar>
            </AppBar>
            <Drawer open={open} onClose={toggle}>
                <div
                    role="presentation"
                    className={classes.sideBar}>
                    <List>
                        <Link to="/#projects" onClick={toggle}>
                            <ListItem button key='projects'>
                                <ListItemIcon><AmpStories /></ListItemIcon>
                                <ListItemText>Projects</ListItemText>
                            </ListItem>
                        </Link>
                        <Link to="/#about" onClick={toggle}>
                            <ListItem button key='about'>
                                <ListItemIcon><AccountCircle /></ListItemIcon>
                                <ListItemText>About Us</ListItemText>
                            </ListItem>
                        </Link>
                        <Link to="/#support" onClick={toggle}>
                            <ListItem button key='support'>
                                <ListItemIcon><ContactMail /></ListItemIcon>
                                <ListItemText>Support</ListItemText>
                            </ListItem>
                        </Link>
                        <Link to="/privacy" onClick={toggle}>
                            <ListItem button key='support'>
                                <ListItemIcon><Policy /></ListItemIcon>
                                <ListItemText>Privacy Policy</ListItemText>
                            </ListItem>
                        </Link>
                    </List>
                </div>
            </Drawer>
        </div>
    );
}

export default Navbar;