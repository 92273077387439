import React from 'react';
import L from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
    },
}));

function Link(props) {
    const classes = styles();
    const { to, children, newtab=false } = props;

    return (
        <L href={`${to}`} underline="none" color="textPrimary" className={`${classes.link} ${props.className}`} onClick={props.onClick} rel={newtab ? "noopener noreferrer" : ""} target={newtab ? "_blank" : ""}>
            {children}
        </L>
    );
}

export default Link;