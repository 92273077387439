import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from './Link';
import { Typography } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        borderRadius: 10,
        position: 'relative',
    },
    image: {
        maxWidth: '100%',
        height: 700,
        ["@media (max-width:768px)"]: { height: 500 },
        ["@media (min-width:2200px)"]: { height: 1100 },
        objectFit: 'cover',
        objectPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: 10,
        transition: 'all 0.5s ease',
        '&:hover': {
            transform: 'scale(1.1)',
        }
    },
    section: {
        width: '100%',
        maxWidth: '100%',
        height: 'auto',
        position: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        bottom: theme.spacing(6),
        padding: theme.spacing(0, 8),
    },
    title: {
        color: theme.palette.text.secondary,
        textTransform: 'uppercase',
        fontFamily: 'Roboto',
        fontSize: '1.8rem',
        fontWeight: 500,
        lineHeight: 1.26,
    }
}));

function Project({ image, to, title }) {
    const classes = styles();

    return (
        <div className={classes.root}>
            <Link to={to}>
                <img src={image} alt={title} className={classes.image} />
                <section className={classes.section}>
                    <Typography variant="overline" className={classes.title}>
                        {title}
                    </Typography>
                </section>
            </Link>
        </div>
    );
}

export default Project;