import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import reportWebVitals from './reportWebVitals';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import './static/styles/index.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const headerFont = ['Cookie', 'cursive'].join(',');
const buttonFont = ['Roboto', 'sans-serif'].join(',');
const regularFont = ['Roboto Slab', 'serif'].join(',');

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#000',
    },
    text: {
      primary: '#000',
      secondary: '#ffffff',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 8,
      },
    },
  },
  typography: {
    fontFamily: regularFont,
    h1: {
      fontSize: '5.8rem',
      fontFamily: headerFont,
    },
    h2: {
      fontSize: '4.2rem',
      fontFamily: headerFont,
    },
    h3: {
      fontSize: '3rem',
      fontFamily: headerFont,
    },
    h4: {
      fontSize: '2.2rem',
      fontFamily: headerFont,
    },
    h5: {
      fontSize: '1.8rem',
      fontFamily: headerFont,
    },
    h6: {
      fontSize: '1.6rem',
      fontFamily: headerFont,
    },
    button: {
      fontFamily: buttonFont,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
