import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Link from '../Widgets/Link';

const styles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4, 0),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: theme.spacing(4),
    },
    titleContainer: {
        alignItems: 'center',
        textAlign: 'center',
        gap: theme.spacing(4),
    },
    body: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        gap: theme.spacing(4),
        textAlign: 'center',
    },
}));

function Darryl() {
    const classes = styles();

    useEffect(() => {
        document.title = "Privacy Policy";
    });

    return (
        <div className={classes.root}>
            <div className={classes.titleContainer}>
                <Typography variant="h1" className={classes.title}>Privacy Policy</Typography>
            </div>
            <div className={classes.body}>
                <Typography variant="body1">This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</Typography>
                <Typography variant="body1">If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</Typography>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>What (purposes) do we use the data for</Typography>
                <Typography variant="body1">CookieDoh Studios uses this data to understand how much and how people play our games. This policy clarifies how and why that happens.</Typography>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Where do we obtain the data from (sources)</Typography>
                <Typography variant="body1">BloonScape collects information used for statistical purposes through Unity and Google Analytics platforms. For information about their privacy policy, please read <Link to="https://unity3d.com/legal/privacy-policy"><b style={{ textDecoration: 'underline' }}>Unity's privacy policy</b></Link> and <Link to="https://policies.google.com/privacy"><b style={{ textDecoration: 'underline' }}>Google's privacy policy</b></Link></Typography>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>What happens to the data</Typography>
                <Typography variant="body1">Your data is stored on the afformentioned third-party platforms, and consumed by CookieDoh Studios in aggregate form.</Typography>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Sharing of data</Typography>
                <Typography variant="body1">Since this data is collected by third party platforms, they have access as well. CookieDoh Studios will not share your data to any third party unless required to do so by law or regulation.</Typography>
            </div>
        </div>
    );
}

export default Darryl;