import React from 'react';
import About from '../Widgets/About';
import Introduction from '../Widgets/Introduction';
import Projects from '../Widgets/Projects';
import Support from '../Widgets/Support';
import Divider from '@material-ui/core/Divider';

function Home() {
    return (
        <div>
            <Introduction />
            <Projects />
            <Divider variant="middle" />
            <About />
            <Divider variant="middle" />
            <Support />
        </div>
    );
}

export default Home;