import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import chess from '../../static/images/chess_king.jpg';

const styles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4, 0),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        minHeight: 400,
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    media: {
        width: 400,
        maxWidth: '100%',
        marginBottom: theme.spacing(2),
        borderRadius: 10,
    },
    text: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
        alignSelf: 'center',
    }
}));

function About() {
    const classes = styles();

    return (
        <div id="about" className={classes.root}>
            <div className={classes.column}>
                <img src={chess} alt="Chess" className={classes.media}/>
            </div>
            <div className={classes.column}>
                <Typography variant="h3" className={classes.text}>
                    Our Vision and Values
                </Typography>
                <Typography variant="body1" className={classes.text}>
                    <b>To be creators of games and applications that help people relax and achieve a balanced mind and life.</b>
                </Typography>
                <Typography variant="body1" className={classes.text}>
                    Humans thrive on competition and hard work but need to stay balanced and creative outside the workplace for long term happiness.
                    Our games and apps will offer people creative tools to balance their mind and life.
                    At CookieDoh Studios, we let our lives speak for us by striving to stay balanced in how we live, create, work and treat others.
                    We pledge that our work, like our life, will reflect our values such as a balanced life and respect for social and environmental justice.
                    We intend to be balanced even in our growth by allocating rewards of our work and company profits between socially responsible and fun projects.
                    When you download or buy our products, you need to know you are supporting a small entrepreneurial team whose main goal is not short term profits at any cost.
                    We dislike and discourage violent games or those that degrade human dignity and compassion.
                </Typography>
            </div>
        </div>
    );
}

export default About;