import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import trademark from '../../static/images/logo/trademark.png';
import { Typography } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        maxHeight: 400,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: theme.spacing(4, 0),
        alignSelf: 'end',
    },
    trademark: {
        height: 45,
    }
}));

function Footer() {
    const classes = styles();

    return (
        <div id="footer" className={classes.root}>
            <img src={trademark} className={classes.trademark} alt="trademark" />
            <Typography variant="body2" style={{margin: 20}}>
                © {new Date().getFullYear()} CookieDoh Studios LLC. All rights reserved.
            </Typography>
        </div>
    );
}

export default Footer;