import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './Home';
import Privacy from './Privacy';
import BloonScape from './BloonScape';

const Router = () => {
  return (
    <Switch>
      <Route exact path="/privacy" component={Privacy}/>
      <Route exact path="/bloonscape" component={BloonScape} />
      <Route path="/" component={Home} />
    </Switch>
  );
}

export default Router;
