import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import Link from './Link';
import Button from '@material-ui/core/Button';

const styles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4, 0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    email: {
        marginBottom: theme.spacing(4),
        '& > a': {
            color: red[400],
            '&:hover': {
                color: red[600],
                fontWeight: 'bold',
            },
        },
    },
    innerContent: {
        flex: 1,
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

function Support() {
    const classes = styles();

    return (
        <div id="support" className={classes.root}>
            <Typography variant="h3">
                Support
            </Typography>

            <div className={classes.innerContent}>
                <Typography variant="body1" className={classes.email}>
                    For support or general inquiries, contact us at <Link to="mailto: support@cookiedohstudios.com">support@cookiedohstudios.com</Link>
                </Typography>

                <Typography variant="body1" className={classes.email}>
                    For Press & Media inquiries, contact us at <Link to="mailto: media@cookiedohstudios.com">media@cookiedohstudios.com</Link>
                </Typography>

                <Link to="https://www.twitter.com/dohy_studios" newtab><Button variant="outlined" color="inherit">Twitter</Button></Link>
            </div>
        </div>
    );
}

export default Support;